import {getCartProducts} from "./index"
import {getConsumptionItems} from "../../rumi/selector";

export function calculateCartAmount(state) {
    const {customerType, applyDiscount} = state.client;
    const cart = getCartProducts(state);
    const result = cart.reduce((acc, item, index) => {
        const excises = item.excisesConfig;
        const [taxesInclude, taxesNonInclude] = (applyDiscount && (item.applyTaxDiscount || false)) ? [0, 0] : [excises.taxesInclude, excises.taxesNonInclude];
        let price = item.price;
        if (item.specialPrices.length != 0) {
            price = (item.specialPrices[0][customerType]) ? item.specialPrices[0][customerType] : item.price;
        }
        const basePrice = (price / (1 + taxesInclude + excises.chargesInclude));
        const tax = (basePrice * (taxesInclude + taxesNonInclude));
        const charge = (basePrice * (excises.chargesInclude + excises.chargesNonInclude));
        const amount = basePrice + tax + charge;
        const qty = item.quantity || 1;

        acc.total += (amount * qty);
        acc.taxes += (tax * qty);
        acc.charges += (charge * qty);
        acc.subTotal += (basePrice * qty);
        return acc
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const countryCode = localStorage.getItem('country')
    const paymentSecondaryCurrency = state.paymentTypeSelected.activedPaySecondCurrency

    if(countryCode==='CL' && paymentSecondaryCurrency){
        result['taxes'] = result['subTotal'] * 0.19
        result['total'] = result['subTotal'] + result['taxes'] + result['charges']
    }

    Object.entries(result).forEach(([key, value]) => {
        result[key] = value.toFixed(2)
    });

    return result
}

export function calculateAllConsumptionDetails(state) {
    const concept = "accommodation";
    const countryCode = localStorage.getItem('country')
    const taxValue = localStorage.getItem('taxValue')
    const guestTaxDiscount = state.client.applyDiscount
    const paymentSecondaryCurrency = state.paymentTypeSelected.activedPaySecondCurrency
    const accos = getConsumptionItems(state, concept);
    const accoDetail = Object.values(accos).filter(acco => acco.quantity !== 0).reduce((accu, selectedAcco) => {
            accu.total += selectedAcco.price.total;
            accu.taxes += selectedAcco.price.taxes;
            accu.charges += selectedAcco.price.services;
            accu.subTotal += selectedAcco.price.subtotal;
            return accu
        }, {total: 0, taxes: 0, charges: 0, subTotal: 0}
    );
    if(countryCode==='CL'){
        if(paymentSecondaryCurrency){
            if(guestTaxDiscount){
                accoDetail['taxes'] = 0
                accoDetail['total'] = accoDetail['subTotal'] + accoDetail['taxes'] + accoDetail['charges']
            }else{
                accoDetail['taxes'] = accoDetail['subTotal'] * taxValue
                accoDetail['total'] = accoDetail['subTotal'] + accoDetail['taxes'] + accoDetail['charges']
            }
        }else{
            accoDetail['taxes'] = accoDetail['subTotal'] * taxValue
            accoDetail['total'] = accoDetail['subTotal'] + accoDetail['taxes'] + accoDetail['charges']
        }
        return accoDetail
    }
    return accoDetail
}


export function calculateConsumptions(state) {
    const accos = calculateAllConsumptionDetails(state);
    const ancies = state.selectedConsumption.ancillary.items;
    const accosDiscount = state.selectedConsumption.accommodation.discount;
    const anciesDiscount = state.selectedConsumption.ancillary.discount;

    const accoTotal = Object.values(ancies).filter(ancie => ancie.code === 'ACCO' && ancie.selected).reduce((accu, item) => {
        accu.total = item.price.total;
        accu.taxes = item.price.taxes;
        accu.charges = item.price.services;
        accu.subTotal = item.price.subtotal;
        return accu
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const barTotal = Object.values(ancies).filter(ancie => ancie.code === 'BAR' && ancie.selected).reduce((accu, item) => {
        accu.total = item.price.total;
        accu.taxes = item.price.taxes;
        accu.charges = item.price.services;
        accu.subTotal = item.price.subtotal;
        return accu
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const kitchenTotal = Object.values(ancies).filter(ancie => ancie.code === 'KIT' && ancie.selected).reduce((accu, item) => {
        accu.total = item.price.total;
        accu.taxes = item.price.taxes;
        accu.charges = item.price.services;
        accu.subTotal = item.price.subtotal;
        return accu
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const souvenirsTotal = Object.values(ancies).filter(ancie => ancie.code === 'SOUV' && ancie.selected).reduce((accu, item) => {
        accu.total = item.price.total;
        accu.taxes = item.price.taxes;
        accu.charges = item.price.services;
        accu.subTotal = item.price.subtotal;
        return accu
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const servicesTotal = Object.values(ancies).filter(ancie => ancie.code === 'SERV' && ancie.selected).reduce((accu, item) => {
        accu.total = item.price.total;
        accu.taxes = item.price.taxes;
        accu.charges = item.price.services;
        accu.subTotal = item.price.subtotal;
        return accu
    }, {total: 0, taxes: 0, charges: 0, subTotal: 0});

    const totals = [
        accoTotal.total,
        barTotal.total,
        kitchenTotal.total,
        souvenirsTotal.total,
        servicesTotal.total,
        accos.total
    ];

    const taxes = [
        accoTotal.taxes,
        barTotal.taxes,
        kitchenTotal.taxes,
        souvenirsTotal.taxes,
        servicesTotal.taxes,
        accos.taxes
    ];

    const charges = [
        accoTotal.charges,
        barTotal.charges,
        kitchenTotal.charges,
        souvenirsTotal.charges,
        servicesTotal.charges,
        accos.charges
    ];

    const subtotals = [
        accoTotal.subTotal,
        barTotal.subTotal,
        kitchenTotal.subTotal,
        souvenirsTotal.subTotal,
        servicesTotal.subTotal,
        accos.subTotal
    ];

    const fullTotal = totals.reduce((total, accu) => total + accu, 0.00) - accosDiscount - anciesDiscount;
    let fullTaxes = taxes.reduce((tax, accu) => tax + accu, 0.00);
    const fullCharges = charges.reduce((charge, accu) => charge + accu, 0.00);
    const fullSubtotal = subtotals.reduce((subtotal, accu) => subtotal + accu, 0.00);
    const currencyCode = localStorage.getItem('currencyCode')

    return {
        total: fullTotal.toFixed(2),
        taxes: fullTaxes.toFixed(2),
        charges: fullCharges.toFixed(2),
        subTotal: fullSubtotal.toFixed(2)
    }
}