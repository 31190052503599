import {
    ACTIVE_PAY_SECOND_CURRENCY,
    CLEAR_CURRENCY_VALUES,
    FETCHING_RESERVATIONS,
    FETCHING_ROOMS,
    GENERATE_CASH_REPORT,
    GET_CONSUMPTIONS,
    GET_GUESTS,
    GET_PAYMENT_TYPES,
    GET_PAYMENTS,
    LOAD_BALANCE_FLOWS_REPORT,
    LOAD_BALANCE_STATUS,
    LOAD_CASH_REPORT,
    LOAD_CATEGORIES,
    LOAD_CLOSE_BALANCE,
    LOAD_CONSUMPTIONS_HISTORY,
    LOAD_CREDIT_NOTES_REPORT,
    LOAD_FAILED_SALES,
    LOAD_GUEST_FLOWS_REPORT,
    LOAD_INVOICES_REPORT,
    LOAD_PRODUCTS,
    LOAD_RANKING_REPORT,
    LOAD_RESERVATION,
    LOAD_ROOMS,
    LOAD_SALES_BALANCE,
    LOAD_SALES_HISTORY,
    LOAD_SALES_REPORT,
    LOAD_TICKETS_REPORT,
    OPEN_BALANCE,
    POST_PAYMENT,
    REQUEST_OPEN_BALANCE,
    SET_CURRENCY_VALUES,
    SET_INCLUDE_FUTURE_RESERVATIONS,
    SUCCESS_PAYMENT
} from "./actionTypes";

import * as api from '../apiCalls';
import {postRequestCancelSale, requestDownloadDocumentsPdf} from '../apiCalls';
import {preparePaymentRequest} from "./selector/payment.selector";
import {formatReservation} from "../utils/formatReservation";
import {cleanLocalStorage} from "../../common/utils";
import {handleAlerts, openLoaderProgress, requestError} from '../../common/actions/actions'
import {setClient} from "./actions";
import {
    CLEAR_COMPANY_FLAG,
    CLEAR_COMPANY_INFO,
    CLEAR_RRHH_PAYMENT_FLAG,
    SET_GROUP_PAYMENT,
    SET_RRHH_PAYMENT_FLAG
} from "../../rumi/actionTypes";
import {GET_RESPONSE} from "../../common/actions/actionTypes"
import {verifyToken, verifyTokenRequest} from "../../common/apiCalls";
import {openModalView} from "../../common/actions/utilsActions"


export const getProducts = () => {
    return async dispatch => {
        try {
            await verifyToken();
            let response = await api.requestProducts();
            dispatch({
                type: LOAD_PRODUCTS,
                products_list: response.data
            });

        } catch (err) {

        }
    }
};

export const getCategories = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestCategories();

            dispatch({
                type: LOAD_CATEGORIES,
                categories: response.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err);
            }
        }
    }
};

// BALANCE
export const loadSalesByBalance = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const response = await api.requestSalesByBalance();
            dispatch({
                type: LOAD_SALES_BALANCE,
                payload: response.data[0]
            })

        } catch (err) {
            console.log('Not getting error');
        }

    }
};

export const loadSalesHistory = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const response = await api.requestSalesHistory();
            dispatch({
                type: LOAD_SALES_HISTORY,
                value: response.data
            })
        } catch (err) {
            dispatch(requestError(err))
        }
    }
};

export const loadConsumptionsHistory = () => {
    return async dispatch => {
        try {
            await verifyToken();
            const response = await api.requestConsumptionsHistory();
            dispatch({
                type: LOAD_CONSUMPTIONS_HISTORY,
                value: response.data
            })
        } catch (err) {
            dispatch(requestError(err))
        }
    }
};

export const loadBalanceStatus = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestBalanceStatus();
            dispatch({
                type: LOAD_BALANCE_STATUS,
                value: response.data
            })
        } catch (err) {
            dispatch(requestError(err))
        }
    }
};

export const loadCloseBalance = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestClosedBalance();
            dispatch({
                type: LOAD_CLOSE_BALANCE,
                value: response,
            })
        } catch (err) {
            dispatch(requestError(err))
        }
    }
};

/* BALANCE ACTION CREATORS */
export const openBalance = (currencyData) => {
    return (dispatch) => {
        api.requestOpenBalance(currencyData).then(response => {
                const balance = response.data;
                dispatch({
                    type: OPEN_BALANCE,
                    balance
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};

export const retrieveOpenBalance = () => {
    return (dispatch) => {
        api.requestExistingBalance().then(response => {
                const openedBalance = response.data;
                dispatch({
                    type: REQUEST_OPEN_BALANCE,
                    openedBalance
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};

export const sendAndCloseShift = (primaryCashDrop, primaryCashCardDeposit, secondaryCashDrop, secondaryCashCardDeposit, history) => {
    return async dispatch => {
        try {
            await verifyToken();
            const closing_shift_information = {
                "primary_next_turn": primaryCashDrop,
                "primary_close_balance": primaryCashCardDeposit,
                "secondary_next_turn": secondaryCashDrop,
                "secondary_close_balance": secondaryCashCardDeposit
            };
            const FileDownload = require('js-file-download');
            const response = await api.requestCloseShift(closing_shift_information);
            if (response.data !== '') {
                dispatch(handleAlerts('success', 'Shift closed and end of session', true));
                setTimeout(function () {
                    FileDownload(response.data, "CashBalance.xlsx");
                    dispatch(openModalView(""))
                }, 1500)
                setTimeout(function () {
                    cleanLocalStorage();
                    dispatch(handleAlerts('close', 'close alert', false));
                    history.push("/logout")
                }, 3000);
            }
        } catch (err) {
            dispatch(requestError(err))
            dispatch(handleAlerts('error', 'Failed close shift', true));
        }

    };
};


export const loadGuests = () => {
    return async (dispatch) => {
        api.requestGuests().then(response => {
                const guests = response.data;
                dispatch({
                    type: GET_GUESTS,
                    guests
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};

//DELETE THIS UNUSED FUNCTION
export const loadConsumptions = () => {
    return async (dispatch) => {
        api.requestConsumptions().then(response => {
                const consumptions = response.data;
                dispatch({
                    type: GET_CONSUMPTIONS,
                    consumptions
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};

export const loadPaymentTypes = () => {
    return async (dispatch) => {
        api.requestPaymentTypes().then(response => {
                const payments = response.data;
                dispatch({
                    type: GET_PAYMENT_TYPES,
                    payments
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};


export const requestPayments = () => {
    return async (dispatch) => {
        api.requestPayments().then(response => {
                const payments = response.data;
                dispatch({
                    type: GET_PAYMENTS,
                    payments
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};


export const _processPayment = (paymentData) => {
    return async (dispatch) => {
        api.requestProcessPayment(paymentData).then(response => {
                const payment = response.data;
                dispatch({
                    type: POST_PAYMENT,
                    payment
                })
            },
            error => {
                if (error.response) {
                    dispatch(requestError(error))
                } else {
                    console.log('Not getting error')
                }
            })
    }
};

const calculateDevolution = (primary, secondary, exchangeRate, totalToPay) => {
    const countryCode = localStorage.getItem('country')
    let totalPaid
    const totalToPayInSecondaryCurrency = (totalToPay*exchangeRate);
    if(countryCode === 'CL'){
        const difference = secondary - totalToPayInSecondaryCurrency.toFixed(2)
        // console.log("difference: ",difference)
        if(difference === 0 || (difference < 0 && Math.abs(difference)<0.001)){
            totalPaid = totalToPayInSecondaryCurrency / exchangeRate
        }else{
            totalPaid = primary + (secondary / exchangeRate);
        }
    }else{
        totalPaid = primary + (secondary * exchangeRate);
    }
    let devolution = 0;
    let lowerPayAlert = false;
    let higherPayAlert = false;
    // console.log("primary",primary)
    // console.log("secondary",secondary)
    // console.log("exchangeRate",exchangeRate)
    // console.log("totalPaid",totalPaid)
    // console.log("totalToPay",totalToPay)
    // console.log("totalToPayInSecondaryCurrency",totalToPayInSecondaryCurrency)
    // console.log("totalToPay - TotalPaid",totalToPay-totalPaid)
    if (totalPaid === totalToPay) {
        devolution = 0;
        lowerPayAlert = false;
        higherPayAlert = false;
    } else {
        if (totalPaid > totalToPay) {
            if(countryCode === 'CL'){
                if (totalPaid - totalToPay <= 1) {
                    devolution = 0
                    lowerPayAlert = false;
                    higherPayAlert = false;
                } else {
                    devolution = totalPaid - totalToPay;
                    higherPayAlert = true;
                    lowerPayAlert = false;
                }
            }else{
                if (totalPaid - totalToPay < 0.1) {
                    devolution = 0
                    lowerPayAlert = false;
                    higherPayAlert = false;
                } else {
                    devolution = totalPaid - totalToPay;
                    higherPayAlert = true;
                    lowerPayAlert = false;
                }
            }
        } else {
            devolution = 0;
            higherPayAlert = false;
            lowerPayAlert = true;
        }
    }
    return {devolution: devolution, lowerPayAlert: lowerPayAlert, higherPayAlert: higherPayAlert}
};

export const setPaymentCurrencyValues = (primary, secondary, exchangeRate, totalToPay) => {
    return async (dispatch) => {
        const countryCode = localStorage.getItem('country')
        const calculatedResults = await calculateDevolution(primary, secondary, exchangeRate, totalToPay);
        let secondaryInPrimaryCurrency = secondary * exchangeRate;
        if(countryCode === 'CL'){
            const totalToPayInSecondaryCurrency = (totalToPay*exchangeRate);
            const difference = secondary - totalToPayInSecondaryCurrency.toFixed(2)
            if(difference === 0 || (difference < 0 && Math.abs(difference)<0.001)){
                secondaryInPrimaryCurrency = totalToPayInSecondaryCurrency / exchangeRate
            }else{
                secondaryInPrimaryCurrency = secondary / exchangeRate;
            }
        }
        let newPrimaryValue;
        console.log("secondary in primary: ", secondaryInPrimaryCurrency)
        if (secondaryInPrimaryCurrency >= totalToPay) {
            newPrimaryValue = secondaryInPrimaryCurrency - totalToPay < 0.1 ? 0 : secondaryInPrimaryCurrency - totalToPay;
        } else {
            newPrimaryValue = totalToPay - secondaryInPrimaryCurrency < 0.1 ? 0 : totalToPay - secondaryInPrimaryCurrency;
        }

        let devolution = calculatedResults['devolution']

        dispatch({
            type: SET_CURRENCY_VALUES,
            payload: {
                primaryValue: newPrimaryValue,
                secondaryValue: secondary,
                devolution: devolution,
                insufficientPayment: calculatedResults.lowerPayAlert,
                excessPayment: calculatedResults.higherPayAlert
            }
        })
    }
};

export const activePaySecondCurrency = (value) => {
    return async (dispatch) => {
        dispatch({
            type: ACTIVE_PAY_SECOND_CURRENCY,
            value
        })
    }
};

export const toggleRrhhPayment = (value) => {
    return async (dispatch) => {
        dispatch({
            type: SET_RRHH_PAYMENT_FLAG,
            value
        })
    }
}

export const toggleFutureReservations = (value) => {
    return async (dispatch) => {
        dispatch({
            type: SET_INCLUDE_FUTURE_RESERVATIONS,
            value
        })
    }
}

export const clearPaymentCurrencyValues = () => {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_CURRENCY_VALUES,
        })
    }
};

export const processPayment = (redirectCb, path, currentPath, paymentType, paymentCurrencyValues) => {
    return async (dispatch, getState) => {
        dispatch(openLoaderProgress(true));
        try {
            await verifyToken();
            const paymentData = preparePaymentRequest(getState(), currentPath, paymentCurrencyValues);
            //Validate if is store, products or pos payment
            let response = null;
            if (currentPath.includes('store')) {
                response = await api.postRequestPOSPayment(paymentData);
            } else if (currentPath.includes('consumptions')) {
                response = await api.postRequestBillingPayment(paymentData);
            } else {
                dispatch(handleAlerts('warning', 'No valid origin for the user ', true));
                return
            }

            const FileDownload = require('js-file-download');
            if (response.status === 201) {
                if (response.data !== '') {
                    FileDownload(response.data, "rumibilling_receipt.pdf")
                }
                dispatch({type: SUCCESS_PAYMENT});
                dispatch(openLoaderProgress(false));
                if (paymentType === "CHRG") {
                    dispatch(handleAlerts('success', 'Sale done ', true));
                } else {
                    dispatch(handleAlerts('success', 'Payment done ', true));
                }
                dispatch(setClient({}, ""));
                dispatch({type: SET_GROUP_PAYMENT, payload: false});
                dispatch({type: CLEAR_COMPANY_INFO});
                dispatch({type: CLEAR_COMPANY_FLAG});
                dispatch({type: CLEAR_RRHH_PAYMENT_FLAG, value: false});
                setTimeout(function () {
                    redirectCb(`${path}/clients`)
                }, 1500);
                setTimeout(function () {
                    dispatch(handleAlerts('close', 'close alert', false));
                }, 2000);
            }
        } catch (e) {
            dispatch(openLoaderProgress(false));
            dispatch(handleAlerts('warning', 'Failed operation ', true))
        }
    }
};

export const getPmsRoomsAndClients = () => dispatch => {
    dispatch({type: FETCHING_ROOMS});
    dispatch({type: FETCHING_RESERVATIONS});
    try {
        verifyTokenRequest().then(verification => {
            if (verification.status === 200) {
                try {
                    api.requestRooms().then(rooms => {
                        dispatch({
                            type: LOAD_ROOMS,
                            rooms: rooms.data
                        });
                    }, error => {
                        dispatch(requestError(error));
                    });
                    api.requestGuest().then(guests => {
                        dispatch({
                            type: LOAD_RESERVATION,
                            payload: formatReservation(guests.data)
                        });
                    }, error => {
                        dispatch(requestError(error));
                    });
                } catch (e) {
                    dispatch(requestError(e))
                }
            }
        });
    } catch (e) {
        dispatch(requestError(e))
    }
};

export const getPMSRooms = () => async dispatch => {
    dispatch({type: FETCHING_ROOMS});
    try {
        await verifyToken();
        const response = await api.requestRooms();
        dispatch({
            type: LOAD_ROOMS,
            rooms: response.data
        })
    } catch (e) {
        dispatch(requestError(e))

    }
};

export const getPMSGuest = () => async dispatch => {
    dispatch({type: FETCHING_RESERVATIONS});
    try {
        await verifyToken();
        const response = await api.requestGuest();
        dispatch({
            type: LOAD_RESERVATION,
            payload: formatReservation(response.data)
        })
    } catch (e) {
        dispatch(requestError(e))
    }
};


// REPORTS
export const loadBalanceFlowsReport = () => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestBalanceFlowsReport();
            dispatch({
                type: LOAD_BALANCE_FLOWS_REPORT,
                payload: response.data.flows
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadGuestFlowsReport = (filterValue = "") => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestGuestFlowsReport(filterValue);
            dispatch({
                type: LOAD_GUEST_FLOWS_REPORT,
                payload: response.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadSalesReport = (startDate = "", endDate = "") => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestSalesReport(startDate, endDate);
            dispatch({
                type: LOAD_SALES_REPORT,
                payload: response.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadRankingReport = (startDate = "", endDate = "") => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const response = await api.requestRankingReport(startDate, endDate);
            dispatch({
                type: LOAD_RANKING_REPORT,
                payload: response.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    }
};

export const loadReceiptsReport = (startDate = "", endDate = "") => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const tickets = await api.requestTicketsReport(startDate, endDate);
            const invoices = await api.requestInvoicesReport(startDate, endDate);
            const creditNotes = await api.requestCreditNotesReport(startDate, endDate);
            dispatch({
                type: LOAD_TICKETS_REPORT,
                payload: tickets.data
            });
            dispatch({
                type: LOAD_INVOICES_REPORT,
                payload: invoices.data
            });
            dispatch({
                type: LOAD_CREDIT_NOTES_REPORT,
                payload: creditNotes.data
            });
        } catch (err) {
            if (err.response) {
                dispatch(requestError(err.response));
            } else {
                console.log(err)
            }
        }
    };
};

export const handleFilterSearchSimplePanel = (panelClass, filteringText) => {
    return async (dispatch) => {
        dispatch(openLoaderProgress(true));
        switch (panelClass) {
            case "guest-flows-report":
                try {
                    await verifyToken();
                    const response = await api.requestGuestFlowsReport(filteringText);
                    if (response.status === 200) {
                        dispatch({
                            type: LOAD_GUEST_FLOWS_REPORT,
                            payload: response.data
                        });
                        dispatch(openLoaderProgress(false));
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            default:
                break
        }
    }
}

export const handleFilterSearchPanel = (panelClass, initDate, endDate, initTime, endTime, origin = null) => {
    let initDateTime = "";
    let endDateTime = "";
    if (initDate !== '' && initTime !== '') {
        initDateTime = String(initDate + ' ' + initTime);
    }

    if (endDate !== "" && endTime !== "") {
        endDateTime = String(endDate + ' ' + endTime);
    }

    return async (dispatch) => {
        dispatch(openLoaderProgress(true));
        switch (panelClass) {
            case "sales-report":
                try {
                    await verifyToken();
                    const response = await api.requestXlsxSalesReport(initDateTime, endDateTime);
                    const FileDownload = require('js-file-download');
                    dispatch(openLoaderProgress(false));
                    if (response.data !== '') {
                        FileDownload(response.data, "SalesReport.xlsx");
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            case "sales-ranking":
                try {
                    await verifyToken();
                    const selectedOrigin = origin ? origin : "";
                    const response = await api.requestXlsxRankingReport(initDateTime, endDateTime, selectedOrigin);
                    const FileDownload = require('js-file-download');
                    dispatch(openLoaderProgress(false));
                    if (response.data !== '') {
                        FileDownload(response.data, "RankingReport.xlsx");
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            case "tickets-report":
                try {
                    await verifyToken();
                    const response = await api.requestXlsxTicketsReport(initDateTime, endDateTime);
                    const FileDownload = require('js-file-download');
                    dispatch(openLoaderProgress(false));
                    if (response.data !== '') {
                        FileDownload(response.data, "TicketsReport.xlsx");
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            case "invoices-report":
                try {
                    await verifyToken();
                    const response = await api.requestXlsxInvoicesReport(initDateTime, endDateTime);
                    const FileDownload = require('js-file-download');
                    dispatch(openLoaderProgress(false));
                    if (response.data !== '') {
                        FileDownload(response.data, "InvoicesReport.xlsx");
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            case "credit-notes-report":
                try {
                    await verifyToken();
                    const response = await api.requestXlsxCreditNotesReport(initDateTime, endDateTime);
                    const FileDownload = require('js-file-download');
                    dispatch(openLoaderProgress(false));
                    if (response.data !== '') {
                        FileDownload(response.data, "CreditNotesReport.xlsx");
                    }
                } catch (err) {
                    if (err.response) {
                        dispatch(requestError(err.response));
                    } else {
                        console.log(err)
                    }
                }
                break;
            default:
                break
        }
    }
};

export const generateCashReport = id => {
    return async dispatch => {
        await verifyToken();
        const balance_id = {"id": id};
        const FileDownload = require('js-file-download');
        const response = await api.requestReportCashBalance(balance_id);
        dispatch({
            type: GENERATE_CASH_REPORT,
        });
        if (response.data !== '') {
            FileDownload(response.data, "CashBalance.xlsx")
        }
    }
};

export const retrieveReservationsAllTime = () => {
    return async dispatch => {
        await verifyToken();
        const FileDownload = require('js-file-download');
        const response = await api.requestReservationsAllTime();
        if (response.data !== '') {
            FileDownload(response.data, "ReservationsAllTime.json")
        }
    }
};

export const retrieveProductPricesFile = () => {
    return async dispatch => {
        await verifyToken();
        const FileDownload = require('js-file-download');
        const response = await api.getProductPricesFile();
        if (response.data !== '') {
            FileDownload(response.data, "ProductPricesFile.csv")
        }
    }
};

export const retrieveReservationsAllTimeDetailedRates = () => {
    return async dispatch => {
        await verifyToken();
        const FileDownload = require('js-file-download');
        const response = await api.requestReservationsAllTimeDetailedRates();
        if (response.data !== '') {
            FileDownload(response.data, "ReservationsAllTimeDetailedRates.json")
        }
    }
};

export const loadCashReport = (date) => {
    return async dispatch => {
        await verifyToken();
        const value = await api.requestCashBalance(date);
        dispatch({
            type: LOAD_CASH_REPORT,
            value: value.data
        })
    }
};

export const loadFailedSales = () => {
    return async dispatch => {
        try {
            const value = await api.requestFailedSales();
            dispatch({
                type: LOAD_FAILED_SALES,
                value: value.data
            })
        } catch (e) {


        }

    }
};


export const processCancelSale = (saleHistory) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            const data = {'saleId': saleHistory.id, 'key': saleHistory.key};
            let response = await postRequestCancelSale(data);
            const FileDownload = require('js-file-download');
            setTimeout(() => {
                if (response.data !== '') {
                    FileDownload(response.data, "creditNote.pdf");
                }
                dispatch(loadSalesHistory());
            }, 3000);
            dispatch(handleAlerts('success', 'Sale removed ', true));
            setTimeout(() => {
                dispatch(handleAlerts('close', '', false));
            }, 800);
            dispatch({
                type: GET_RESPONSE,
                response: {
                    status: response.status,
                    method: response.config.method,
                    id: saleHistory.id,
                    key: "delete-sale"
                }
            })
        } catch (err) {
            dispatch({
                type: GET_RESPONSE,
                response: {}
            });
            dispatch(handleAlerts('error', 'Failed remove', true));

        }
    }
};

export const downloadDocumentPDF = (correlative) => {
    return async (dispatch) => {
        try {
            await verifyToken();
            let response = await requestDownloadDocumentsPdf(correlative);
            const FileDownload = require('js-file-download');
            setTimeout(() => {
                if (response.data !== '') {
                    FileDownload(response.data, "receipt.pdf");
                }
            }, 1000);
            dispatch(handleAlerts('success', 'Document Downloaded ', true));
            setTimeout(() => {
                dispatch(handleAlerts('close', '', false));
            }, 800);
        } catch (err) {
            dispatch({
                type: GET_RESPONSE,
                response: {}
            });
            dispatch(handleAlerts('error', 'Failed download', true));

        }
    }
}